module.exports = {
    pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
    title: 'Zoé Ouvrier', // Navigation and Site Title
    titleAlt: 'Zoé Ouvrier', // Title for JSONLD
    description: 'Zoé Ouvrier Artiste Plasticien .',
    headline: 'Zoé Ouvrier Artiste Plasticien', // Headline for schema.org JSONLD
    url: process.env.GATSBY_SITE_URL, // Domain of your site. No trailing slash!
    siteLanguage: 'en', // Language Tag on <html> element
    logo: '/images/ZO_favicon.png', // Used for SEO
    ogLanguage: 'en_US', // Facebook Language
  
    // JSONLD / Manifest
    favicon: 'src/static/images/ZoeOuvrier_favicon.png', // Used for manifest favicon generation
    shortName: 'ZoeOuvrier', // shortname for manifest. MUST be shorter than 12 characters
    author: 'Isayas', // Author for schemaORGJSONLD
    themeColor: '#3D63AE',
    backgroundColor: '#EBEDF2',
  
    twitter: '@ZoeOuvrier', // Twitter Username
    facebook: 'zoe.ouvrier', // Facebook Site Name
    googleAnalyticsID: 'UA-XXXXXX',
  
    skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature

    prismicLanguage: process.env.GATSBY_PRISMIC_LANGUAGE
  };
  