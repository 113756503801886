import { graphql, StaticQuery } from "gatsby"
import React from "react"
import CommonLayout from "./CommonLayout"

const artQuery = graphql`
  query ArtMenuQuery {
    prismicMenu(uid: { eq: "art-menu" }) {
      data {
        display_name {
          text
        }
        prefix
        nav {
          ... on PrismicMenuNavNavItem {
            id
            primary {
              label {
                text
              }
              link {
                document {
                  __typename
                  ... on PrismicPage {
                    uid
                  }
                  ... on PrismicClientList {
                    uid
                  }
                }
              }
            }
            items {
              sub_nav_link {
                document {
                  __typename
                  ... on PrismicPage {
                    uid
                  }
                  ... on PrismicClientList {
                    uid
                  }
                }
              }
              sub_nav_link_label {
                text
              }
            }
          }
        }
      }
    }
  }
`

const Art = ({ mt, ...props }) => {
  return (
    <StaticQuery
      query={artQuery}
      render={data => <CommonLayout mt={mt} data={data} {...props} />}
    />
  )
}

export default Art
