import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { animated } from 'react-spring';
import styled, { css } from 'styled-components';
import {
  border,
  color,
  flexbox,
  fontSize,
  grid,
  layout,
  space,
  textAlign,
  width,
} from 'styled-system';

export const Box = styled.div`
  box-sizing: border-box;
  ${space}
  ${width}
  ${color}
  ${grid}
  ${flexbox}
  ${textAlign}
  ${fontSize}
  ${flexbox}
  ${layout}
`;

Box.displayName = 'Box';

export const Grid = styled.div`
  box-sizing: border-box;
  display: grid;
  ${grid}
  ${layout};
  ${color};
  ${border};
`;

Grid.displayName = 'Grid';

export const AnimatedBox = styled(animated.div)`
  box-sizing: border-box;
  ${space}
  ${width}
  ${color}
  ${flexbox}
  ${textAlign}
`;

AnimatedBox.displayName = 'AnimatedBox';

export const Flex = styled.div`
  display: flex;
  position: relative;
  ${space}
  ${flexbox}
  ${color}
  ${layout}
`;

Flex.displayName = 'Flex';

export const FlexItem = styled.div`
  ${space}
  ${flexbox}
  ${color}
  ${layout}
`;
FlexItem.displayName = 'FlexItem';

export const FlexHome = styled.div`  
  display: flex;
  align-items: "center"; 
  justify-content: "center";
  ${flexbox}    
`;
FlexItem.displayName = 'FlexHome';

export const AnimatedFlex = styled(animated.div)`
  display: flex;
  ${space}
  ${flexbox}
`;

AnimatedFlex.displayName = 'AnimatedFlex';

export const Button = styled.button`
  border-radius: 1000rem; 
  border: none;
  font-weight: 700;
  font-size: 1.25rem;
  display:flex;
  justify-content:center;
  background-color: transparent;
  outline:none;
  padding:0;
  margin:0;
  &:hover {
    cursor: pointer;
  }
  ${space}
  ${width}
  ${color}
  ${layout}
  ${flexbox}
`;

Button.displayName = 'Button';

export const Content = styled.div`
  ${props => props.theme.devices.mobile} {
    padding: ${props => `0 ${props.theme.space[6]}`};
  }

  ${props => props.theme.mediaQueries.md} {
    padding: ${props => `0 ${props.theme.space[7]}`};
  }
  ${props => props.theme.mediaQueries.lg} {
    padding: ${props => `0 ${props.theme.space[8]}`};
  }

  ${props => props.theme.mediaQueries.xl} {
    padding: ${props => `0 ${props.theme.space[9]}`};
  }
`;

Button.displayName = 'Content';

export const Image = styled.img`
  object-fit: cover;
`;
Image.displayName = 'Image';

export const ImgHover = styled(Img)`
  display: none;
`;
ImgHover.displayName = 'ImgHover';

export const ImgStyled = styled(Img)``;
ImgStyled.displayName = 'ImgStyled';

export const TitleHover = styled.span`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  text-align: center;
  display: none;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: #ffffff;
  opacity: 0.75;
  font-size: 16px;
  font-weight: bold;
  font-family: Arial;
  word-break: break-word;
`;
TitleHover.displayName = 'TitleHover';

export const StyledLink = styled(Link)`
  display: block;
  &:hover > ${TitleHover}, &:hover > ${ImgHover} {
    display: flex;
  }
  ${props =>
    props.isRolloverImage &&
    css`
      &:hover > ${ImgStyled} {
        display: none;
      }
    `}
`;
StyledLink.displayName = 'StyledLink';

export const StyledA = styled.a`
  display: block;
  &:hover > ${TitleHover}, &:hover > ${ImgHover} {
    display: flex;
  }
  ${props =>
    props.isRolloverImage &&
    css`
      &:hover > ${ImgStyled} {
        display: none;
      }
    `}
`;
StyledA.displayName = 'StyledA';
