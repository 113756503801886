import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { layout } from 'styled-system';
import logoArt from '../../static/images/ZoeOuvrier_logo.svg';
import { Button, Flex } from '../elements';

const Header = styled.header`
  display: ${props => (props.header ? 'flex' : 'none')};
  flex-direction: column;
  background-color: #333333;
  border-bottom: 1px solid #9f9f9f;
  flex-shrink: 0;
  ${layout}
`;

const MobileHeader = ({ header, menu, setToggleSideNav, toggleSideNav, location, ...rest }) => {
  return (
    <Header header={header} {...rest}>
      <Flex m="10px" justifyContent="space-between" alignItems="center">
        {!toggleSideNav && (
          <Button onClick={() => setToggleSideNav(!toggleSideNav)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="none" d="M0 0h24v24H0V0z" />
              <path fill="white" d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
            </svg>
          </Button>
        )}
        <div>
          <Link to="/">
            {location.pathname.includes('/art') && (
              <img src={logoArt} height="35px" alt="Zoé Ouvrier Artworks" />
            )}
            
          </Link>
        </div>
        <div css="width: 24px" />
      </Flex>
    </Header>
  );
};

export default MobileHeader;
