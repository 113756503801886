import { Link } from 'gatsby';
import React from 'react';
import logoArt from '../../static/images/ZoeOuvrier_logo.svg';
import { Flex } from '../elements';

const BreadCrumb = ({ crumbs, crumbSeparator, ...rest }) => {
  const isDesign = crumbs.filter(crumb => crumb.pathname === '/design').length !== 0;
  let newCrumbs = [];
  //if(crumbs) newCrumbs = crumbs.slice(0,crumbs.length - 1)
  if(crumbs) newCrumbs = crumbs.slice()
  return (
    <Flex {...rest} alignItems="center">
      {
        newCrumbs.map((crumb, index) => {
          return (
            <Flex key={crumb.pathname} alignItems="center">
              {index === 0 ? (
                <Link
                  to={crumb.pathname}
                  style={{ ...crumb.crumbStyle }}
                  activeStyle={{ ...crumb.crumbActiveStyle }}
                >                  
                  <img
                    src={logoArt}                      
                    height="40px"
                    alt="Zoé Ouvrier"
                  />                  
                </Link>
              ) : (
                <Link
                  to={crumb.pathname}
                  style={{ ...crumb.crumbStyle }}
                  activeStyle={{ ...crumb.crumbActiveStyle }}
                  css="margin-right: 5px;margin-left:5px;"
                >
                  {crumb.crumbLabel}
                </Link>
              )}

              {index !== newCrumbs.length - 1 && <span>{crumbSeparator}</span>}
            </Flex>
          );
        })}
    </Flex>
  );
};

export default BreadCrumb;
